import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';

const AlumniSpotlight = () => (
  <Layout title="Alumni Spotlight" className="alumni-center">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-blue"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../alumni-center/overview">Overview</Link>
          </li>
          <li className="breadcrumb active">Alumni Spotlight</li>
        </ul>
        <h1 className="page-title">Alumni Spotlight</h1>
        <Image filename="hero-alumni.png" className="hero-image" />
      </div>
    </div>
    {/* Alumni Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>Meet the Alumni</h2>
            <h4>
              Meet the talented challenge finalists who make up the 3M Young
              Scientist Challenge Alumni Network. Hear Alumni share their
              challenge stories in their own words, and find out more about the
              amazing scientific innovations that were explored in previous
              years’ challenges.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Alumni Highlight */}
    <Section className="mb-4 alumni">
      <Container>
        <Row>
          {/* ADDED APR2024 */}
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Crouchley-Caroline-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Caroline Crouchley</h3>
                <p>
                  Since participating in the 2019 Challenge, Caroline has been
                  researching to investigate new drip irrigation methods to grow
                  plants more efficiently and conserve water. Caroline is
                  excited to be part of the Alumni Network!
                </p>
                <Link to="caroline-crouchley" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          {/* <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Herbst-Hannah-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2015 Finalist</span>
                <h3>Hannah Herbst</h3>
                <p>
                  After the 3M Young Scientist Challenge, Hannah continued to work on her project, BEACON, an ocean energy collection and conversion device. Hannah attended Florida Atlantic University and Stanford University, and is now an entrepreneur working on some incredible projects!
                </p>
                <Link to="hannah-herbst" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Willis-Devin-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Devin Willis</h3>
                <p>
                  Devin’s 3M Young Scientist Challenge project was a device, SlideMap, designed to expedite and enhance the precision of cancer diagnosis. He is currently studying electrical engineering at the University of Florida.
                </p>
                <Link to="devin-willis" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column> */}
          {/* END APR2024 ADDITIONS */}
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Sami-Shanza-2022-Finalist-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2022 Finalist</span>
                <h3>Shanza Sami</h3>
                <p>
                  Since participating in the 3M Young Scientist Challenge,
                  Shanza has continued to work on her innovation, Pura Aurem.
                  She has begun additional scientific pursuits, and has an
                  interest in journalism.
                </p>
                <Link to="shanza-sami" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Pandey-Viraj-2021-Finalist-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2021 Finalist</span>
                <h3>Viraj Pandey</h3>
                <p>
                  Now in 10th grade, Viraj is working on a new project aimed at
                  re-inventing farming strategies. Viraj enjoys science, history
                  and 3D modeling, and is in his school’s gardening club.
                </p>
                <Link to="viraj-pandey" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Faraz-Tamboli-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Faraz Tamboli</h3>
                <p>
                  Since participating in the 2019 Challenge, Faraz has continued
                  to improve and enhance his entry project, <em>Talk Motion</em>
                  . Faraz is currently in 9th grade and enjoys reading fantasy
                  stories, making his own music and participating in Mock Trial
                  Club.
                </p>
                <Link to="faraz-tamboli" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Anna-Du-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2018 Finalist</span>
                <h3>Anna Du</h3>
                <p>
                  Anna participated in the 2018 3M Young Scientist Challenge and
                  continues to work on her innovation today. Now in 11
                  <sup>th</sup> grade, Anna is extremely passionate about the
                  environment and loves reading and astronomy.
                </p>
                <Link to="anna-du" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>

          {/* Mehaa Amirthalingam's profile will launch in early 2023 */}
          {/*  <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Mehaa-Amirthalingam-2018-Finalist-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2018 Finalist</span>
                <h3>Mehaa Amirthalingam</h3>
                <p>Still need...</p>
                <Link to="mehaa-amirthalingam" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>*/}
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Benedict-Braeden-2011-Finalist-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2011 Winner</span>
                <h3>Braeden Benedict</h3>
                <p>
                  Braeden is currently a medical student at Washington
                  University in St. Louis, with an interest in neurosurgery.
                  After participating in the 2011 3M Young Scientist Challenge,
                  Braeden continued working on his innovation and received his
                  bachelor’s degree in electrical engineering.
                </p>
                <Link to="braeden-benedict" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AlumniSpotlight;
